import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { checkVAT, countries } from 'jsvat-next';

const BELGIAN_NATIONAL_NUMBER_REGEX = /[0-9]{11}/g;

export class CustomValidator {
  public static notEmpty({ value }: AbstractControl): ValidationErrors | null {
    if (typeof value === 'string') {
      const empty = !value || !value?.trim()?.length;
      return empty ? { empty: { value } } : null;
    }
    return null;
  }

  public static validVat({ value }: AbstractControl, addPrefix = false): ValidationErrors | null {
    const valueToCheck = addPrefix ? 'BE' + value : value;
    return value &&
      checkVAT(valueToCheck, countries).isSupportedCountry &&
      !checkVAT(valueToCheck, countries).isValid
      ? { custom: 'VALIDATION-ERRORS.VAT-NUMBER.INVALID' }
      : null;
  }

  public static expirationDate({ value }: AbstractControl): ValidationErrors | null {
    const present: Date = new Date(Date.now());
    const val: Date = new Date(value);
    return value && present.getTime() > val.getTime()
      ? { custom: 'VALIDATION-ERRORS.EXP-DATE' }
      : null;
  }

  public static birthDate({ value }: AbstractControl): ValidationErrors | null {
    const present: Date = new Date(Date.now());
    return value && present.getTime() < value.getTime()
      ? { custom: 'VALIDATION-ERRORS.BIRTHDATE' }
      : null;
  }

  public static validBelgianNationalNumber({ value }: AbstractControl): ValidationErrors | null {
    if (value) {
      if (BELGIAN_NATIONAL_NUMBER_REGEX.test(value) && modulo97Check(value)) {
        return null;
      } else {
        return { custom: 'VALIDATION-ERRORS.NATIONAL-NUMBER.INVALID' };
      }
    }

    return null;
  }

  public static validVatOrBelgianNationalNumber(control: AbstractControl): ValidationErrors | null {
    const validVat = CustomValidator.validVat(control, true);
    const validRRN = CustomValidator.validBelgianNationalNumber(control);
    return validVat && validRRN ? { custom: 'Invalid RRN or VAT' } : null;
  }

  public static validMandateType({ value }: AbstractControl): ValidationErrors | null {
    const mandateTypes = [
      'BEPS13',
      'BIZTAX',
      'VAT',
      'CUSTOMSPRO',
      'DISPUTE',
      'E705',
      'edepo',
      'EXCISEPRO',
      'FINPROF',
      'UBO',
      'NBB',
      'OPC_MANDATE',
      'MDT-276CONV',
      'MDT-RVPM'
    ];
    return value && mandateTypes.includes(value) ? null : { custom: 'Invalid mandate type' };
  }

  public static stringEquals(val: string): ValidatorFn {
    return ({ value }: AbstractControl): ValidationErrors | null => {
      return value === val ? null : { custom: `Given value does not equal ${val}` };
    };
  }
}

function modulo97Check(nationalNumber: string): boolean {
  // result = 97 - (x % 97)
  const modFun = (x: number) => 97 - (x % 97);

  const checkDigit = parseInt(nationalNumber.substring(9, 11));
  let numberToCheck = parseInt(nationalNumber.substring(0, 9));

  // Check for people born before year 2000
  if (modFun(numberToCheck) == checkDigit) return true;

  //Check for people born after year 2000
  numberToCheck = parseInt(nationalNumber.substring(0, 9)) + 2000000000;
  return modFun(numberToCheck) == checkDigit;
}
